import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import getString from '../utils/get-string';

const ErrorPage = ({strings}) => (
  <Layout>
    <Seo
      title={`404 - ${getString(strings, "11256116")}`}
      description={getString(strings, "11256359")}
    />
    <Page type="error">
      <Header closeUrl="/lessons">{getString(strings, "11256338")}</Header>
      <Content center={true}>
        <h1 className="growing text-transform-uppercase">
          {getString(strings, "11256116")}
        </h1>
        <div className="page-text-container">
          <p className="h6">
            {getString(strings, "11256359")}
          </p>
        </div>
      </Content>
      <Footer>
        <FooterLinkNext to="/lessons">{getString(strings, "11220979")}</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11256116", "11256338", "11256359", "11220979"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <ErrorPage {...data} {...props} />}
  />
)
